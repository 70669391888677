<template>
    <div class="addPlanName">
        <el-dialog class="addPlanNameDialog flex justify-center align-center" :class="[!Dark ? '' : 'dark']" :title="(add ? '添加' : '编辑') + addPlanName" :visible.sync="visible" :append-to-body="true" :before-close="cancel" :close-on-click-modal="false" :close-on-press-escape="false">
            <div class="scroll">
                <el-form>
                    <el-form-item>
                        <div style="display:flex;align-items: center;justify-content: space-between;">
                            <el-select :popper-append-to-body="false" v-model="time_point" placeholder="请选择时间段">
                                <el-option v-for="item in time_point_list" :value="item.value" :label="item.label" :key="item.value">{{ item.label }}</el-option>
                            </el-select>
                            <el-input type="number" v-if="type > 2" style="padding-left: 20px" v-model="time_interval" placeholder="请输入相隔时间">
                                <span slot="append">分钟</span>
                            </el-input>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <el-select
                            :popper-append-to-body="false"
                            :placeholder="'请输入' + addPlanName + '名称'"
                            v-model="addName"
                            filterable
                            remote
                            :clearable="true"
                            :remote-method="search"
                            not-found-text="暂无数据"
                            :loading="loading"
                            ref="clearInput"
                        >
                            <el-option :popper-append-to-body="false" v-for="(option, index) in options" :value="option.id" :label="option.title" :key="index">{{option.title}}</el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <div class="details">
                            <div class="item" v-for="(item,index) in details" :key="index">
                                <div class="name">{{item.title}}</div>
                                <div class="ipArea">
                                    <el-input type="number" v-model="item.num" size="small">
                                        <el-select :popper-append-to-body="false" v-model="item.unit_id" slot="append">
                                            <el-option v-for="(item,index) in item.unit_list" :key="index" :label="item.title" :value="item.id">{{item.title}}</el-option>
                                        </el-select>
                                    </el-input>
                                    <i class="el-icon-delete-solid icon pointer pion-rel" @click="itemDelete(index)" />
                                </div>
                            </div>
                            <el-input placeholder="请输入备注" v-model="note" />
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            <div class="history">
                <div class="title">历史记录</div>
                <div class="list">
                    <div class="item" v-for="(item,index) in historyList" :key="index" @click="addByHistory(item)"><p>{{item.title}}</p></div>
                </div>
            </div>

            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取消</el-button>
                <el-button class="save" @click="addPlanOver">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {medicineSearch,sportsSearch,foodSearch} from 'quest';
    export default {
        props: {
            addParameter: Object,
            Dark: Boolean
        },
        watch:{
            addParameter:{
                handler(obj){
                    this.visible = obj.show,
                    this.type = obj.add ? obj.addType : obj.type,
                    this.startTime = obj.startDay,
                    this.day = obj.day,
                    this.from = obj.from;
                    this.add = obj.add;
                    this.init();
                    !obj.add ? this.index = obj.index : '',
                    this.addPlanName = !obj.add && '' || obj.title,
                    this.time_point = obj.plan ? parseInt(obj.plan.time_point) : '',
                    this.time_interval = obj.plan ? obj.plan.time_interval : '',
                    this.note = obj.plan ? obj.plan.note : '';
                    obj.from == 'details' ? this.uid = this.$route.query.id : '';
                    if( obj.plan != undefined){
                        if(JSON.stringify(obj.plan) != '{}'){
                            this.details = obj.plan ? obj.plan.details : '',
                            this.planTime = obj.plan.kind == 1 ? obj.plan.food_time : obj.plan.kind == 2 ? obj.plan.medicine_time : obj.plan.sports_time;
                            obj.plan.id && !obj.add ? this.id = obj.plan.id : '';
                            obj.plan.user_id ? this.uid = obj.plan.user_id : '';
                            obj.plan.food_list ? this.food_list = obj.plan.food_list : '';
                        }
                    }
                },
                deep: true
            },
            addName(id){
                let that = this;
                if(id){
                    this.options.forEach(item => {
                        if(item.id == id){
                            let repect = false;
                            this.details.forEach(el =>{
                                if(el.id == id){
                                    repect = true;
                                }
                            })
                            //去重
                            if(!repect){
                                this.details.push(item);
                                // 定义函数表达式
                                const fixList = (name) => {
                                    let historyList = Object.assign([], JSON.parse(window.localStorage.getItem(name)) || []);
                                    if (historyList.length > 5) {
                                        historyList = historyList.slice(-5);
                                    }
                                    historyList.push(item);
                                    window.localStorage.setItem(name, JSON.stringify(historyList));
                                    that.historyList = historyList;
                                };
                                //食物
                                if(item.food_id){
                                    fixList('foodListHistory')
                                }
                                //口服药
                                if(item.medicine_id && item.type == 1){
                                    fixList('medicineListHistory')
                                }
                                //胰岛素
                                if(item.medicine_id && item.type == 2){
                                    fixList('ydsListHistory')
                                }
                                //运动
                                if(item.sports_id){
                                    fixList('sportsListHistory')
                                }
                            }else{
                                this.$message.warning('请勿重复添加');
                            }
                        }
                    })
                }
                this.addName = '';
                this.options = [];
            },
        },
        data(){
            return{
                visible: false,
                addPlanName:'',
                time_point: '',
                time_point_list: [
                    {label:'早餐前',value: 1},
                    {label:'早餐',value: 2},
                    {label:'早餐后',value: 3},
                    {label:'午餐前',value: 4},
                    {label:'午餐',value: 5},
                    {label:'午餐后',value: 6},
                    {label:'晚餐前',value: 7},
                    {label:'晚餐',value: 8},
                    {label:'晚餐后',value: 9},
                    {label:'睡前',value: 10}
                ],
                type: 0,
                time_interval: '',
                addName: '',
                uid: '',
                loading: false,
                options: [],
                details:[],
                note: '',
                historyList:[],
                from: '',
                startTime : '',
                day: '',
                add: false,
            }
        },
        methods:{
            init(){
                this.details = [];
                this.options = [];
                this.index = '';
                this.planTime = '';
                this.addPlanName = '';
                switch(this.type){
                    //饮食
                    case 1:
                        this.historyList = JSON.parse(window.localStorage.getItem('foodListHistory'));
                    break;
                    //口服药
                    case 2:
                        this.historyList = JSON.parse(window.localStorage.getItem('medicineListHistory'));
                    break;
                    //胰岛素
                    case 3:
                        this.historyList = JSON.parse(window.localStorage.getItem('ydsListHistory'));
                    break;
                    //运动
                    case 4:
                        this.historyList = JSON.parse(window.localStorage.getItem('sportsListHistory'));
                    break;
                }
            },
            addPlanOver(){
                if(this.time_point == '' ){
                    this.$message.warning('请选择时间段');
                }else{
                    if(this.details.length > 0 ){
                        let is = false;
                        let title = '';
                        this.details.map( item =>{
                            if(item.num == null || item.num == 0){
                                title += item.title;
                                is = true;
                            }else{
                                is = false;
                            }
                        })
                        if(is){
                            this.$message.warning('请填写' + JSON.stringify(title) + '数量');
                        }else{
                            this.plan(this.type);
                        }
                    }else{
                        this.$message.warning('请添加' + this.addPlanName);
                    }
                }
            },
            cancel(){
                this.visible = false;
                this.$emit("closeAdd", this.visible);
            },

            search(title){
                if(title){
                    this.loading = true
                    let quest = this.type == 1 ? foodSearch({
                            title
                        }) : ( this.type == 2 ? medicineSearch({
                            title,
                            type: 1
                        }) : ( this.type == 3 ? medicineSearch({
                            title,
                            type:2
                        }) : sportsSearch({
                            title
                        })));
                    quest.then( res =>{
                        this.loading = false;
                        if(res.data.code == 100000){
                            res.data.data.forEach(item => {
                                item.id = this.type == 1 ? item.food_id : this.type == 4 ? item.sports_id : item.medicine_id,
                                item.unit_id = item.unit_list[0].id;
                            });
                            this.options = res.data.data;
                        }
                    })
                }
            },
            itemDelete(index){
                this.details.splice(index, 1);
            },
            plan(type){
                let detail = [];
                this.details.map( item =>{
                    let arr = {
                        title: item.title,
                        num: item.num,
                        unit_id: item.unit_id,
                        [type == 1 ? 'food_id' : (type == 4 ? 'sports_id' : 'medicine_id' ) ]: type == 1 ? item.food_id : ( type == 4 ? item.sports_id : item.medicine_id )
                    }
                    detail.push( arr );
                })
                let plan = {
                        title: this.addPlanName,
                    time_point: this.time_point,
                    source: this.from == 'programme' ? 3 : 2,
                    [type == 1 ? 'food_time' : ( type == 4 ? 'sports_time' : 'medicine_time' ) ] : this.add ? this.startTime : this.planTime,
                    [type == 1 ? 'food_record_detail' : ( type == 4 ? 'sports_record_detail' : 'medicine_record_detail' ) ] : JSON.stringify(detail),
                    details: this.details,
                    kind: type == 1 ? 1 : ( type == 4 ? 3 : 2 ),
                },
                eventName = !this.add != '' ? 'editPlan' : 'addPlan',
                index = !this.add ? this.index : '';
                this.from == 'details' ? plan.user_id = this.uid : '',
                this.note != '' ? plan.note = this.note : '',
                this.id && !this.add ? plan.id = this.id : '',
                this.food_list ? plan.food_list = this.food_list : '';
                type > 2 && this.time_interval != '' ? plan.time_interval = this.time_interval : '',
                type == 2 ? plan.type = 1 : type == 3 ? plan.type = 2 : '';
                this.visible = false;
                this.$emit(eventName, plan, index, this.visible);

            },
            addByHistory(item){
                let flag = true;
                this.details.forEach(el=>{
                    if(el.id == item.id){
                        this.$message.warning('请勿重复添加');
                        flag = false
                        return
                    }
                })
                if(flag){
                    this.details.push(item);
                }
            },
        }
    }
</script>

<style lang="less">

    .addPlanNameDialog{
        width: 100%;
        height: 100%;

        .el-dialog{
            width: 30%;
            height: 70%;
            overflow: hidden;
            border-radius: 10px;
            margin: 0 !important;
            .el-dialog__header{
                border-bottom: 1px solid #dcdfe6;
            }
        }

        .el-select{
            width: 100%;
            margin: 0px;
        }

        .el-dialog__body{
            height: ~'calc(100% - 120px)';
            overflow: hidden;
            .scroll{
                height: ~'calc(100% - 100px)';
                overflow-y: scroll;
            }

            input{
                &::placeholder{
                    font-size: 12px;
                }
            }

            .el-input-group--append{
                width: 100% !important;
            }

            .details{
                min-height: 150px;
                max-height: 200px;
                overflow-y: scroll;

                .item{
                    box-sizing: border-box;
                    padding: 0 8px;
                    height: 30px;
                    background: #F7FBFC;
                    border-radius: 4px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 12px;

                    .name{
                        font-size: 12px;
                        color: #242424;
                        max-width: 120px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    .ipArea{
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;

                        .el-input-group__append{
                            padding: 0px;
                            width: 50px;
                        }

                        .el-input-group--append{
                            display: flex;
                            max-width: 100px !important;
                        }

                        input{
                            width: 50px;
                            height: 24px;
                            padding-right: 0px;
                        }
                        
                        .el-input--suffix{

                            input{
                                padding-right: 20px;
                            }

                            .el-icon-arrow-up{
                                position: relative;
                                top: 4px;
                                left: 5px;

                                &.is-reverse{
                                    top: -5px;
                                }
                            }
                        }

                        .icon{
                            font-size: 14px;
                            margin-left: 8px;
                            z-index: 999;
                        }
                    }
                }
            }

            .history{
                position: absolute;
                bottom: 75px;
                width: ~'calc(100% - 40px)';
                height: 75px;
                overflow-y: scroll;

                .title{
                    font-size: 13px;
                    color: #242424;
                }
                .list{
                    display: flex;
                    flex-wrap: wrap;
                    .item{
                        cursor: pointer;
                        height: 22px;
                        padding: 0 8px;
                        box-sizing: border-box;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #9F9F9F;
                        font-size: 12px;
                        background-color: #F7FBFC;
                        border-radius: 4px;
                        margin: 6px 6px 0 0;
                        p{
                            max-width: 120px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }

        .el-dialog__footer{
            width: 100%;
            position: absolute;
            bottom: 0px;
            border-top: 1px solid #dcdfe6;
            button{
                padding: 5px 15px 6px;
                font-size: 12px;
                border-radius: 4px;
                line-height: 1.6;
                font-weight: normal;
                transition: .3s;

                span{
                    display: block;
                    padding-top: 2px;
                }

                &.delete{
                    position: absolute;
                    left: 20px;
                    background: #ff6e6e;
                    border-color: #ff6e6e;
                    color: white !important;
                    &:hover,
                    &:focus{
                        background: #eb3f3f;
                        border-color: #eb3f3f !important;
                    }
                }

                &.save{
                    transition: .3s;
                    background: var(--defalutColor);
                    border-color: var(--defalutColor);
                    color: white;

                    &:hover{
                        background-color: var(--defalutActiveColor);
                        border-color: var(--defalutActiveColor);
                        color: white !important;
                        transition: .3s;
                    }
                }
            }
        }
    }

    .addPlanNameDialog.dark{
        .el-dialog{
            background: #202025 !important;
            border-color: #2c2c2e !important;
            .el-dialog,
            .el-dialog__header,
            .el-dialog__body,
            .el-dialog__footer{
                background: #202025 !important;
                border-color: #2c2c2e !important;
                .el-tabs__header{
                    border-color: #2c2c2e !important;
                }
                .el-dialog__title{
                    color: #c1c1c1;
                }
                .details{
                    .item{
                        background: #202025 !important;
                        &>div{
                            color: #c1c1c1 !important;
                        }
                    }
                }
                input{
                    background-color: unset;
                    color: #c1c1c1;
                    border-color: #2c2c2e !important;
                    &::placeholder{
                        color: #c1c1c1 !important;
                        border-color: #2c2c2e !important;
                    }
                }
                .el-input-group__append{
                    background-color: #202025;
                    border-color: #2c2c2e;
                }
                .history{
                    .list{
                        .item{
                            background-color: #0d0e10;
                            color: #c1c1c1;
                        }
                    }
                }

                .el-select-dropdown{
                    background-color: #202025;
                    border-color: #0c0c0c;
                    .hover{
                        background-color: #0c0c0c;
                    }
                    .el-select-dropdown__item{
                        color: #c1c1c1;
                    }
                    .popper__arrow{
                        border-bottom-color: #0c0c0c;
                        &::after{
                            border-bottom-color: #0c0c0c;
                        }
                    }
                }
            }
        }
    }

</style>